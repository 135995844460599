<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title color="white">Immagini perizia</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <div v-if="processingPhotos" class="loading_spinner">
                    <ion-text color="dark">
                        <h5 class="ion-text-center">Salvataggio in corso, il processo potrebbe richiedere del tempo in base alla connessione</h5>
                        <h5 class="ion-text-center">{{ submitted }} di {{ allPhotosCounter }} processate correttamente</h5>
                    </ion-text>
                    <ion-spinner name="crescent" color="dark"></ion-spinner>
                </div>

                <div v-else>
                    <div class="container_actions">
                        <div @click="closeModal()" class="btn_actions_images">Chiudi</div>
                        <div @click="savePhoto()" class="btn_actions_images">Salva</div>
                    </div>

                    <div class="category_container" style="display: none;">
                        <ion-select
                            name="tipologia"
                            interface="action-sheet"
                            cancel-text="Annulla"
                            ok-text="Conferma"
                            v-model="categoriaSelezionata"
                            value="1"
                            placeholder="Seleziona la categoria"
                            @ionChange="setCategoria($event.target.value)"
                        >
                            <ion-select-option
                                v-for="categoria in categorieFoto"
                                :key="categoria.rapportini_immagini_categoria_id"
                                :value="categoria.rapportini_immagini_categoria_id"
                            >
                                {{ categoria.rapportini_immagini_categoria_value }}
                            </ion-select-option>
                        </ion-select>

                        <span @click="removeSelectedCategory()">
                            <ion-icon :icon="close" class="remove_category"></ion-icon>
                        </span>
                    </div>

                    <div class="foto_button_container">
                        <button class="foto_button" @click="showGalleryPicker()">Scegli dalla galleria</button>
                        <button class="foto_button" @click="showCameraPicker()">Scatta foto</button>
                    </div>

                    <div class="photos_container">
                        <ion-grid>
                            <ion-row>
                                <ion-col size="6" :key="photo" v-for="photo in userPhoto">
                                    <ion-img v-if="photo.photoFromGallery" :src="photo.webviewPath" class="img_photo"></ion-img>
                                    <ion-img v-else :src="`data:image/png;base64,${photo.webviewPath}`" class="img_photo"></ion-img>
                                    <div @click="showActionSheet(photo)" class="img_action">
                                        <ion-icon :icon="trash"></ion-icon>
                                    </div>
                                </ion-col>
                            </ion-row>
                        </ion-grid>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    modalController,
    IonGrid,
    IonRow,
    IonCol,
    IonImg,
    IonIcon,
    actionSheetController,
    IonText,
    IonSelect,
    IonSelectOption,
    IonSpinner,
} from "@ionic/vue";
import { camera, trash, images, close } from "ionicons/icons";
import { defineComponent, ref, reactive, onMounted } from "vue";

import { openToast } from "@/services/toast";

import apiRapportini from "@/services/rapportini";
import apiPerizie from "@/services/perizie";

//import { usePhotoGallery, UserPhoto } from "@/services/usePhotoGalleryRapportino";
import { usePhotoGallery, UserPhoto } from "@/services/immagini_perizia";
import imageToBase64 from "image-to-base64/browser";

export default defineComponent({
    name: "PickerFotoPerizia",
    props: {
        images: {
            type: Array,
            required: false,
        },
        perizia_id: {
            type: String,
            required: true,
        },
    },
    components: {
        IonPage,
        IonContent,
        IonHeader,
        IonTitle,
        IonToolbar,
        IonGrid,
        IonRow,
        IonCol,
        IonImg,
        IonIcon,
        IonText,
        IonSelect,
        IonSelectOption,
        IonSpinner,
    },
    setup(props, context) {
        //console.log(props);

        //Camera functionality
        const { userPhoto, takePhoto, deletePhoto, pickPhotos, deleteAllPhotos } = usePhotoGallery();
        const processingPhotos = ref(false);
        const dipendenteID = JSON.parse(localStorage.getItem("userInfoAssitech")).dipendenti_id;

        /**
         * ! Handle close modal by clicking on Chiudi button or after upload foto
         * ! Send back data to visualize photos in Modal Pulizia form
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        async function closeModalOnSubmit(res) {
            if (res) {
                await modalController.dismiss(res);
            } else {
                await modalController.dismiss();
            }
        }

        /**
         * ! Geolocalizzazione foto
         */
        const geoOptions = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
        };

        const sendingCoordinates = ref(false);
        async function getPosition(options) {
            return new Promise((resolve, reject) =>
                navigator.geolocation.getCurrentPosition(
                    function(position) {
                        // L'utente ha fornito l'autorizzazione alla geolocalizzazione
                        //console.log(position);
                        const latitude = position.coords.latitude;
                        const longitude = position.coords.longitude;

                        return resolve(position);
                    },
                    function(error) {
                        //console.log(error);
                        if (error.code === error.PERMISSION_DENIED) {
                            sendingCoordinates.value = false;
                            openToast("Geolocalizzazione spenta o negata l'autorizzazione", "toast_danger");
                        } else {
                            sendingCoordinates.value = false;
                            openToast("Si è verificato un errore durante la geolocalizzazione", "toast_danger");
                        }
                        return reject(error);
                    },
                    options
                )
            );
        }

        /*****************************************************************
         *
         * ! Selezione e rimozione categoria selezionata per le foto
         *
         ******************************************************************/
        const categorieFoto = ref([]);
        let selectedPhotoCategory = null;
        const categoriaSelezionata = ref(null);

        async function loadCategorie() {
            try {
                const res = await apiRapportini.getCategorieFoto();
                if (res.status === 200 && res.data.status === 0) {
                    categorieFoto.value = res.data.data;
                } else {
                    openToast("Errore durante la richiesta delle categorie", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta delle categorie", "toast_danger");
            }
        }

        async function setCategoria(categoria_id) {
            if (categoria_id && categoria_id != "") {
                categoriaSelezionata.value = categoria_id;
                //await showActionSheetPhotos(categoria_id);
            } else {
                categoriaSelezionata.value = null;
            }
        }

        function removeSelectedCategory() {
            categoriaSelezionata.value = null;
        }

        /*****************************************************************
         *
         * ! Selezione foto tramite fotocamera o galleria, imposta categoria foto
         *
         *******************************************************************/
        const showActionSheetPhotos = async () => {
            //if (categoriaSelezionata.value) {
            let pos = null;
            pos = await getPosition(geoOptions);
            //console.log(pos);

            const selectedCategory = categorieFoto.value.find((el) => el.rapportini_immagini_categoria_id === categoriaSelezionata.value);
            let selectedCategoryLabel = "Generale";
            if (selectedCategory) {
                selectedCategoryLabel = selectedCategory.rapportini_immagini_categoria_value;
            }

            selectedPhotoCategory = categoriaSelezionata.value;

            const actionSheet = await actionSheetController.create({
                header: "Scegli modalità di caricamento",
                buttons: [
                    {
                        text: "Fotocamera",
                        icon: camera,
                        handler: () => {
                            takePhoto(selectedPhotoCategory, pos.coords.latitude, pos.coords.longitude, selectedCategoryLabel);
                        },
                    },
                    {
                        text: "Galleria",
                        icon: images,
                        handler: () => {
                            pickPhotos(selectedPhotoCategory, pos.coords.latitude, pos.coords.longitude, selectedCategoryLabel);
                        },
                    },
                    {
                        text: "Annulla",
                        icon: close,
                        role: "cancel",
                        handler: () => {
                            // Nothing to do, action sheet is automatically closed
                        },
                    },
                ],
            });
            await actionSheet.present();
            //} else {
            //openToast("Devi selezionare una catagoria prima di scegliere le foto", "toast_danger");
            //}
        };

        /*****************************************************************
         *
         * ! Selezione foto tramite fotocamera o galleria, imposta categoria foto
         *
         *******************************************************************/
        const showGalleryPicker = async () => {
            //if (categoriaSelezionata.value) {
            let pos = null;
            pos = await getPosition(geoOptions);
            //console.log(pos);

            const selectedCategory = categorieFoto.value.find((el) => el.rapportini_immagini_categoria_id === categoriaSelezionata.value);
            let selectedCategoryLabel = "Generale";
            if (selectedCategory) {
                selectedCategoryLabel = selectedCategory.rapportini_immagini_categoria_value;
            }

            selectedPhotoCategory = categoriaSelezionata.value;

            pickPhotos(selectedPhotoCategory, pos.coords.latitude, pos.coords.longitude, selectedCategoryLabel);
        };

        /*****************************************************************
         *
         * ! Selezione foto tramite fotocamera o galleria, imposta categoria foto
         *
         *******************************************************************/
        const showCameraPicker = async () => {
            //if (categoriaSelezionata.value) {
            let pos = null;
            pos = await getPosition(geoOptions);
            //console.log(pos);

            const selectedCategory = categorieFoto.value.find((el) => el.rapportini_immagini_categoria_id === categoriaSelezionata.value);
            let selectedCategoryLabel = "Generale";
            if (selectedCategory) {
                selectedCategoryLabel = selectedCategory.rapportini_immagini_categoria_value;
            }

            selectedPhotoCategory = categoriaSelezionata.value;

            takePhoto(selectedPhotoCategory, pos.coords.latitude, pos.coords.longitude, selectedCategoryLabel);
        };

        /**
         * ! Handle delete single photo
         */
        const showActionSheet = async (photo) => {
            const actionSheet = await actionSheetController.create({
                header: "Foto",
                buttons: [
                    {
                        text: "Elimina",
                        role: "destructive",
                        icon: trash,
                        handler: () => {
                            deletePhoto(photo);
                        },
                    },
                    {
                        text: "Annulla",
                        icon: close,
                        role: "cancel",
                        handler: () => {
                            // Nothing to do, action sheet is automatically closed
                        },
                    },
                ],
            });
            await actionSheet.present();
        };

        // Iterates all photos, convert to base64 and return.
        const conversioneFoto = async (userPhoto) => {
            const requests = userPhoto.map((photo) => {
                return imageToBase64(photo.webviewPath) // Path to the image
                    .then((response) => {
                        //console.log(response);
                        const fileName = photo.filepath;
                        const foto = {
                            name: fileName,
                            data: response,
                            category: photo.category,
                            latitude: photo.latitude,
                            longitude: photo.longitude,
                            label: photo.label,
                            exif: photo.jsonExif,
                        };
                        return foto;
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            });
            return Promise.all(requests); // Waiting for all the requests to get resolved.
        };

        const imgs_salvate = ref(null);
        //const photosToSave = ref(null);
        const photosToSave = ref([]);
        const submitted = ref(0); //contatore foto salvate
        const allPhotosCounter = ref(0);

        async function savePhoto() {
            if (userPhoto.value.length === 0) {
                openToast("Non puoi salvare senza aver aggiunto almeno una immagine", "toast_danger");
            } else {
                processingPhotos.value = true; //loading invio foto
                const perizia = props.perizia_id;
                //console.log(userPhoto.value);

                const photosFromCamera = [];
                const toBeProcessedPhotos = [];
                let processedPhotos = [];

                userPhoto.value.forEach((el) => {
                    //console.log(el);
                    //Foto da galleria, prima le converto in b64
                    if (el.photoFromGallery) {
                        toBeProcessedPhotos.push(el);
                    } else {
                        //foto da camera, la formatto correttamente
                        photosFromCamera.push({
                            name: el.filepath,
                            data: el.webviewPath,
                            category: el.category,
                            latitude: el.latitude,
                            longitude: el.longitude,
                            exif: el.jsonExif,
                        });
                    }
                });

                if (toBeProcessedPhotos.lenght != 0) {
                    processedPhotos = await conversioneFoto(toBeProcessedPhotos);
                }

                photosToSave.value = [...photosFromCamera, ...processedPhotos];

                console.log(photosToSave.value);

                //Modifico il rapportino inserendo le foto a posteriori dopo averlo effettivamente creato
                if (photosToSave.value.length > 0) {
                    allPhotosCounter.value = photosToSave.value.length;

                    //Salvo ogni singola foto con una chiamata diverse e se con successo aumento counter
                    const apiPromises = photosToSave.value.map(async (photo) => {
                        try {
                            //const response = await apiPerizie.salvaFotoRapportino(photo, props.perizia_id);
                            const response = null;
                            //Aggiorno counter foro salvate
                            submitted.value++;
                            return response;
                        } catch (error) {
                            return { error: true, message: "Errore durante il salvataggio delle immagini" };
                        }
                    });

                    try {
                        const responses = await Promise.all(apiPromises);
                        // Ora hai un array di risposte, puoi verificarle tutte alla fine
                        const allSuccessful = responses.every((response) => response.status === 200 && response.data.status === 1);

                        if (allSuccessful) {
                            openToast("Immagini caricate con successo", "toast_success");
                            //Svuoto foto caricate dal composable e foto appena visualizzate
                            deleteAllPhotos();
                            photosToSave.value = [];
                            //Svuoto categoria selezionata
                            removeSelectedCategory();
                            categoriaSelezionata.value = null;
                            submitted.value = 0;
                            //Chiudo modale
                            closeModal();
                        } else {
                            openToast("Non è stato possibile salvare le immagini selezionate", "toast_danger");

                            // Almeno una chiamata API ha avuto un errore, mostra un alert "errore"
                            // Puoi anche visualizzare un messaggio specifico per ciascuna chiamata che ha avuto un errore
                            /* responses.forEach((response, index) => {
                                console.log(response);
                                if (response) {
                                    openToast(response.txt, "toast_danger");
                                    console.error(`Errore nella chiamata API per la foto ${index}: ${response.message}`);
                                }
                            }); */
                        }
                    } catch (error) {
                        console.error(error);
                        openToast("Non è stato possibile salvare le immagini selezionate", "toast_danger");
                    } finally {
                        submitted.value = 0;
                        processingPhotos.value = false;
                    }

                    //const res = await salvaFoto(photosToSave);
                    //closeModalOnSubmit(photosToSave);
                }
            }
        }

        onMounted(() => {
            //loadCategorie();
        });

        return {
            camera,
            trash,
            close,
            closeModal,
            closeModalOnSubmit,
            userPhoto,
            takePhoto,
            showActionSheet,
            savePhoto,
            processingPhotos,
            pickPhotos,
            showActionSheetPhotos,
            //Gestione categorie foto
            categorieFoto,
            categoriaSelezionata,
            setCategoria,
            removeSelectedCategory,
            //Counter foto salvate
            imgs_salvate,
            submitted,
            allPhotosCounter,
            showGalleryPicker,
            showCameraPicker,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
ion-button {
    --color: #ffffff;
}
.page_content {
    width: 100%;
    position: relative;
    min-height: 100%;
    background: #f8fafc;
    padding: 16px;
}

.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.img_photo {
    position: relative;
}
.img_action {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
    font-size: 14px;
    border-radius: 4px;
    padding: 4px;
}

.select100 {
    max-width: 100% !important;
}

.error_message {
    color: #eb445a;
}
.danger {
    color: #eb445a !important;
}

.alert-wrapper .ion-overlay-wrapper .sc-ion-alert-md {
    width: 100% !important;
}

.alert-wrapper {
    width: 90% !important;
}

/* New rules */
.container_actions_page {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.btn_close {
    --background: #dc2626;
    --background-activated: #b91c1c;
    --background-hover: #b91c1c;
    --background-focused: #b91c1c;
    --box-shadow: none;
    --border-radius: 4px;
    --padding-top: 10px;
    --padding-bottom: 10px;
    --padding-start: 10px;
    --padding-end: 10px;
    --ripple-color: none;
    --color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 16px;
    width: 100%;
}
.btn_save {
    --background: #16a34a;
    --background-activated: #15803d;
    --background-hover: #15803d;
    --background-focused: #15803d;
    --box-shadow: none;
    --border-radius: 4px;
    --padding-top: 10px;
    --padding-bottom: 10px;
    --padding-start: 10px;
    --padding-end: 10px;
    --ripple-color: none;
    --color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    width: 100%;
}

.container_help_text {
    margin-bottom: 16px;
    color: #64748b;
    font-size: 14px;
}

/**
  * New field
  */
.select_images_button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    background-color: #086fa3;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}

.container_actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0 0 20px 0;
}
.btn_actions_images {
    width: 47%;
    display: flex;
    justify-content: center;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    background-color: rgb(226 232 240);
    color: rgb(148 163 184);
}

.category_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
}
.category_container ion-select {
    width: 100%;
}
.remove_category {
    width: 20px;
    height: 20px;
    color: #041219;
}

.foto_button_container {
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.foto_button {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    background-color: #086fa3;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}
</style>
