<template>
    <div class="single_step">
        <div class="title_step_container">
            <div class="icon">
                <ion-icon :icon="checkmarkCircle" :style="{ color: stepCompleted ? '#16a34a' : '#d0d0ff' }"></ion-icon>
            </div>
            <div class="title">2. Checklist</div>
        </div>
        <div class="action_badge" @click="handleOpenChecklist">Compila</div>
    </div>
</template>

<script>
import { defineComponent, toRefs, computed } from "vue";
import { IonIcon } from "@ionic/vue";
import { checkmarkCircle } from "ionicons/icons";

export default defineComponent({
    name: "Checklist",
    props: {
        stepCompleted: {
            type: Boolean,
            required: true,
        },
    },
    emits: ["openChecklist"],
    setup(props, { emit }) {
        const { stepCompleted } = toRefs(props);

        const handleOpenChecklist = () => {
            emit("openChecklist");
        };

        return {
            //stepCompleted,
            checkmarkCircle,
            handleOpenChecklist,
        };
    },
    components: {
        IonIcon,
    },
});
</script>

<style scoped>
.title_step_container {
    display: flex;
    align-items: center;
}

.icon {
    margin-right: 8px;
}

.title {
    font-size: 16px;
    color: #1b1b1b;
}

.action_badge {
    background-color: rgb(226, 230, 254);
    color: rgb(68, 134, 239);
    padding: 4px 8px;
    font-size: 10px;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
}
</style>
