<template>
    <div v-if="photos.length != 0" class="step_img_container">
        <ion-grid>
            <ion-row>
                <ion-col size="4" v-for="(foto, index) in photos" :key="index">
                    <div class="singola_foto">
                        <ion-thumbnail>
                            <img :src="foto.tmp_src" alt="" srcset="" class="img_photo" />
                        </ion-thumbnail>

                        <div class="photo_actions">
                            <div @click="$emit('savePhotosToFileSystem', foto)" class="download_foto">
                                <ion-icon :icon="download" class="download_photo_icon"></ion-icon>
                            </div>
                            <div @click="$emit('removePhoto', foto)" class="rimuovi_foto">
                                <ion-icon :icon="trash" class="remove_photo_icon"></ion-icon>
                            </div>
                        </div>
                    </div>
                </ion-col>
            </ion-row>
        </ion-grid>

        <div class="foto_button download_all_photos" @click="$emit('createZip')">
            Scarica archivio foto
        </div>
        <div class="btn_save_foto_container">
            <button class="foto_button save_foto" @click="$emit('salvaFoto', 'array')" :disabled="savingPhotos">Salva foto selezionate</button>
        </div>
    </div>
</template>

<script>
import { IonGrid, IonRow, IonCol, IonThumbnail, IonIcon } from "@ionic/vue";
import { download, trash } from "ionicons/icons";

export default {
    name: "PhotoGrid",
    props: {
        photos: {
            type: Array,
            required: true,
        },
        savingPhotos: {
            type: Boolean,
            required: true,
            data: false,
        },
    },
    components: {
        IonGrid,
        IonRow,
        IonCol,
        IonThumbnail,
        IonIcon,
    },
    setup() {
        return {
            download,
            trash,
        };
    },
};
</script>

<style scoped>
.singola_foto {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 8px;
}
ion-thumbnail {
    --size: 100px;
    --border-radius: 4px;
}
.singola_foto .photo_actions {
    display: flex;
    justify-content: center;
    gap: 12px;
}
.rimuovi_foto {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
    font-weight: 500;
    font-size: 16px;
    border-radius: 4px;
}
.download_foto {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(226, 230, 254);
    color: rgb(68, 134, 239);
    font-weight: 500;
    font-size: 16px;
    border-radius: 4px;
}
.download_all_photos {
    width: 100%;
    text-align: center;
    padding: 8px;
    background-color: #e0e7ff;
    color: #6366f1;
    font-size: 18px;
    font-weight: 600;
    border-radius: 4px;
    margin-bottom: 12px;
}
.btn_save_foto_container {
    width: 100%;
}
.foto_button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: #ffffff;
    background-color: #086fa3;
    font-size: 18px;
    font-weight: 600;
    border-radius: 4px;
}
.save_foto {
    background-color: #dcfce7;
    color: #16a34a;
}
.save_foto:disabled {
    opacity: 0.6;
    touch-action: none;
}
</style>
