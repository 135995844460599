<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title color="white">Dettagli aggiuntivi</ion-title>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <div class="perizia">
                    <div class="title_card_perizia">Dati Generali</div>
                    <div class="field">
                        <div class="title">N. protocollo</div>
                        <div class="value">
                            {{ perizia.pratiche_assicurative_protocollo }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Cliente</div>
                        <div class="value">
                            {{ riferimentoCliente(perizia) }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Stato pratica</div>
                        <div class="value">
                            <span class="badge" :class="statoPerizia(perizia.pratiche_assicurative_stato)">
                                {{ perizia.tipologie_stati_nome }}
                            </span>
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Rif. CGA</div>
                        <div class="value">
                            {{ perizia.pratiche_assicurative_rif_cga ? perizia.pratiche_assicurative_rif_cga : "-" }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Tipologia danno</div>
                        <div class="value">
                            {{ perizia.tipologie_danni_nome ? perizia.tipologie_danni_nome : "-" }}
                        </div>
                    </div>
                    <div class="field_note description">
                        <div class="title">CGA</div>
                        <div class="value" style="text-align: right;">
                            <a
                                v-if="perizia.pratiche_assicurative_cga && perizia.pratiche_assicurative_cga != '---'"
                                :href="scaricaCGA(perizia.pratiche_assicurative_cga)"
                                class="link_rapportino"
                                target="_blank"
                                download
                            >
                                {{ perizia.pratiche_assicurative_cga ? formatCgaText(perizia.pratiche_assicurative_cga) : "-" }}
                            </a>
                            <span v-else>{{ perizia.pratiche_assicurative_cga ? formatCgaText(perizia.pratiche_assicurative_cga) : "-" }}</span>
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Numero sinistro</div>
                        <div class="value">
                            {{ perizia.pratiche_assicurative_numero_sinistro ? perizia.pratiche_assicurative_numero_sinistro : "-" }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Polizza</div>
                        <div class="value">
                            {{ perizia.pratiche_assicurative_polizza ? perizia.pratiche_assicurative_polizza : "-" }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Ubicazione</div>
                        <div class="value">
                            <a
                                :href="
                                    `https://maps.google.com/?q=${perizia.comuni_label} ${perizia.pratiche_assicurative_indirizzo} ${perizia.pratiche_assicurative_civico}`
                                "
                                target="_blank"
                                class="mobile_link"
                                v-if="perizia.comuni_label && perizia.pratiche_assicurative_indirizzo && perizia.pratiche_assicurative_civico"
                            >
                                {{ setUbicazione() }}
                            </a>
                            <span v-else> - </span>
                        </div>
                    </div>
                    <div class="field_note description">
                        <div class="title">Breve descrizione</div>
                        <div class="value">
                            {{ perizia.pratiche_assicurative_breve_descrizione ? perizia.pratiche_assicurative_breve_descrizione : "" }}
                        </div>
                    </div>
                    <div class="field_note description">
                        <div class="title">Note perizia</div>
                        <div class="value">
                            {{ perizia.pratiche_assicurative_note_perizia ? perizia.pratiche_assicurative_note_perizia : "" }}
                        </div>
                    </div>
                </div>

                <!-- Data -->
                <div class="perizia" style="margin-top: 32px;">
                    <div class="title_card_perizia">Date</div>
                    <div class="field">
                        <div class="title">Sinistro</div>
                        <div class="value">
                            {{ perizia.pratiche_assicurative_data_sinistro ? dateFormat(perizia.pratiche_assicurative_data_sinistro) : "-" }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Incarico</div>
                        <div class="value">
                            {{ perizia.pratiche_assicurative_data_incarico ? dateFormat(perizia.pratiche_assicurative_data_incarico) : "-" }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Collaboratore</div>
                        <div class="value">
                            {{ perizia.pratiche_assicurative_incarico_collaboratore ? dateFormat(perizia.pratiche_assicurative_incarico_collaboratore) : "-" }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Sopralluogo</div>
                        <div class="value">
                            {{ perizia.pratiche_assicurative_data_sopralluogo ? dateFormat(perizia.pratiche_assicurative_data_sopralluogo) : "-" }}
                        </div>
                    </div>
                </div>

                <!-- Assicurato -->
                <div class="perizia" style="margin-top: 32px;">
                    <div class="title_card_perizia">Assicurato</div>
                    <div class="field">
                        <div class="title">Anagrafica</div>
                        <div class="value">
                            {{ `${perizia.assicurati_nome} ${perizia.assicurati_cognome}` }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Città</div>
                        <div class="value">
                            {{ perizia.assicurati_citta }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Indirizzo</div>
                        <div class="value">
                            {{ perizia.assicurati_indirizzo }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Telefono</div>
                        <div class="value">
                            {{ perizia.assicurati_telefono ? perizia.assicurati_telefono : "-" }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Cellulare</div>
                        <div class="value">
                            <span v-if="perizia.assicurati_cellulare">
                                <a :href="`tel:${perizia.assicurati_cellulare}`" class="mobile_link">{{ perizia.assicurati_cellulare }}</a>
                            </span>
                            <span v-else>-</span>
                        </div>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    //IonBackButton,
    IonTitle,
    IonContent,
    IonIcon,
    IonButton,
    modalController,
} from "@ionic/vue";

import { defineComponent, onMounted, ref, computed } from "vue";

import { arrowBackOutline, trash, close } from "ionicons/icons";

import { dateFormat } from "@/services/utils";

import { openToast } from "@/services/toast";

export default defineComponent({
    name: "NewPeriziaDetail",
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonButtons,
        //IonBackButton,
        IonTitle,
        IonContent,
        IonIcon,
        IonButton,
    },
    setup(props, context) {
        const perizia = { ...props.data };

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        /********************************************************
         *
         * ! CAMPI PRATICHE
         *
         ********************************************************/
        function setUbicazione() {
            const fields = [perizia.pratiche_assicurative_indirizzo, perizia.pratiche_assicurative_civico, perizia.comuni_label];
            const finalString = fields.filter(Boolean).join(", ");
            return finalString;
        }

        /**
         * ! Limit customers to 35 charactes
         */
        const riferimentoCliente = (perizia) => {
            if (perizia.assicurati_nome && perizia.assicurati_cognome) {
                return `${perizia.assicurati_nome} ${perizia.assicurati_cognome}`;
            } else if (perizia.assicurati_nome && !perizia.assicurati_cognome) {
                return `${perizia.assicurati_nome}`;
            }
            return `${perizia.assicurati_cognome}`;
        };

        function formatCgaText(cgaRef) {
            const lastIndex = cgaRef.lastIndexOf("/");
            const result = cgaRef.substring(lastIndex + 1);

            if (result) {
                return result;
            }
            return "";
        }

        //Set correct background for intervento status
        const statoPerizia = computed(() => {
            return (statusId) => {
                let className = "";
                if (statusId == 1 || statusId == 9) {
                    //ticket aperti
                    className = "badge_red";
                } else if (statusId == 2 || statusId == 3) {
                    //ticket aperti
                    className = "badge_blue";
                } else if (statusId == 4 || statusId == 5) {
                    //ticket in lavorazione
                    className = "badge_orange";
                } else if (statusId == 6 || statusId == 7) {
                    //ticket in lavorazione
                    className = "badge_green";
                }
                return className;
            };
        });

        function scaricaCGA(docPath) {
            return `${process.env.VUE_APP_BASE_URL}/${docPath}`;
        }

        function fakeDownload() {
            openToast("Download in corso...", "toast_info");
        }

        return {
            close,
            trash,
            arrowBackOutline,
            dateFormat,
            closeModal,
            perizia,
            riferimentoCliente,
            statoPerizia,
            fakeDownload,
            formatCgaText,
            scaricaCGA,
            setUbicazione,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* New rules */
.page_content {
    width: 100%;
    position: relative; /* to be used for new intervento btn */
    padding: 16px;
    min-height: 100%;
    background: #f8fafc;
}

.perizia .title_card {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 12px 6px;
    font-weight: 600;
    color: #475569;
}
.perizia .title_card_perizia {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 12px 6px;
    font-weight: 600;
    color: #475569;
}
.perizia {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.perizia .field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e2e8f0;
}
.perizia .field:last-child {
    border-bottom: none; /* Rimuovi il border-bottom dall'ultimo elemento */
}

.perizia .field_note {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px 6px;
}
.perizia .field_note.description {
    border-bottom: 1px solid #e2e8f0;
}
.perizia .field_note.description:last-of-type {
    border-bottom: none;
}
.field .title,
.field_note .title {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.field .ticket_title {
    text-transform: uppercase;
}
.field_note .title {
    margin-bottom: 8px;
}
.field .value,
.field_note .value {
    font-size: 14px;
}

.badge {
    font-size: 10px;
    font-weight: 600;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}
.badge_green {
    background-color: #dcfce7;
    color: #22c55e;
}
.badge_orange {
    background-color: rgb(254 243 199);
    color: rgb(245 158 11);
}
.badge_red {
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
}

.badge_blue {
    background-color: rgb(219, 234, 254);
    color: rgb(59, 130, 246);
}

.nuovo_intervento {
    width: 100%;
    margin-top: 16px;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
}
.perizia_button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    background-color: #086fa3;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}

/* Elenco interventi */
.interventi_container {
    margin-top: 16px;
}
.interventi_container .interventi_label {
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: bold;
    color: #2a2a2a;
}
.perizia {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.perizia .date {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.perizia .status {
    font-size: 14px;
}

ion-button {
    --color: #ffffff;
}
/** Allegati  */
.allegati_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
}
.allegati_container img {
    width: 200px;
    margin-right: 16px;
}
.perizia .field_allegati {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px;
    border-bottom: 1px solid #e2e8f0;
}
.field_allegati .title {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.field_allegati .title {
    margin-bottom: 8px;
}
.field_allegati .value {
    font-size: 14px;
}

/** Modal selected attachment */
.fade-transition-enter-active,
.fade-transition-leave-active {
    transition: opacity 0.25s ease;
}

.fade-transition-enter-from,
.fade-transition-leave-to {
    opacity: 0;
}
.attachment_modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.75);
    color: #ffffff;
    padding: 24px;
    align-items: center;
}
.close_modal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: #ffffff;
    background-color: #086fa3;
    font-size: 16px;
    font-weight: 500;
    border-radius: 20px;
    margin-top: 16px;
}

.title.rapportino_container {
    display: flex;
    justify-content: center;
    margin-top: 8px;
    font-size: 16px;
}
a.link_rapportino {
    color: #086fa3;
    text-decoration: none;
}

.foto_button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: #ffffff;
    background-color: #086fa3;
    font-size: 18px;
    font-weight: 600;
    border-radius: 4px;
    margin-bottom: 20px;
}
.save_foto {
    background-color: rgb(226 232 240);
    color: rgb(148 163 184);
}
.mobile_link {
    color: #086fa3;
    text-decoration: none;
}

.img_photo {
    position: relative;
}
.img_action {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
    font-size: 14px;
    border-radius: 4px;
    padding: 4px;
}

.category_container {
    margin-bottom: 12px;
}
.category_container {
}

.singola_foto {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 8px;
}
ion-thumbnail {
    --size: 100px;
    --border-radius: 4px;
}
.rimuovi_foto {
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
    font-weight: 500;
    font-size: 14px;
    border-radius: 4px;
    padding: 4px 16px;
}
.download_foto {
    background-color: rgb(226, 230, 254);
    color: rgb(68, 134, 239);
    font-weight: 500;
    font-size: 14px;
    border-radius: 4px;
    padding: 4px 16px;
}
.download_all_photos {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    background-color: rgb(226, 230, 254);
    color: rgb(68, 134, 239);
    font-size: 18px;
    font-weight: 600;
    border-radius: 4px;
    margin-bottom: 20px;
}
.counter_photo_container {
    flex-direction: column;
    margin-bottom: 24px;
}
</style>
