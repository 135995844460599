<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title color="white">Checklist</ion-title>
                <ion-buttons slot="start">
                    <ion-button @click="handleCloseModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <div v-if="loadingChecklist" class="loading_spinner">
                    <h4>Caricamento checklist in corso</h4>
                    <ion-spinner name="crescent" color="primary"></ion-spinner>
                </div>
                <div v-else>
                    <div v-if="checklist && checklist.length != 0" class="checklist">
                        <div v-for="(step, i) in checklist" :key="i" class="single_step">
                            <div class="single_step_title">{{ step.step }}</div>

                            <div v-if="step.domande && step.domande.length != 0" class="questions_container">
                                <div v-for="(domanda, j) in step.domande" :key="j" class="single_field">
                                    <div class="domanda_title">{{ domanda.sondaggi_domande_domanda }}</div>

                                    <!-- Input type (risposta breve) -->
                                    <div v-if="domanda.sondaggi_domande_tipologia_value === 'Risposta breve'">
                                        <ion-input type="text" v-model="formFields[domanda.sondaggi_domande_id]"> </ion-input>
                                    </div>

                                    <!-- Textarea (risposta paragrafo) -->
                                    <div v-if="domanda.sondaggi_domande_tipologia_value === 'Paragrafo'">
                                        <ion-textarea v-model="formFields[domanda.sondaggi_domande_id]" rows="4" :auto-grow="true"></ion-textarea>
                                    </div>

                                    <!-- Radio (risposta singola) -->
                                    <div v-if="domanda.sondaggi_domande_tipologia_value === 'Risposta singola - radio'">
                                        <ion-radio-group
                                            @ionChange="setRadioRisposta($event, domanda.sondaggi_domande_id)"
                                            v-model="formFields[domanda.sondaggi_domande_id]"
                                        >
                                            <ion-item
                                                v-for="(option, index) in domanda.risposte"
                                                :key="index"
                                                lines="none"
                                                class="ion-no-padding ion-no-margin"
                                                style="height: 40px;"
                                            >
                                                <ion-label class="ion-no-padding ion-no-margin">{{ `${option.sondaggi_domande_risposte_risposta}` }}</ion-label>
                                                <ion-radio
                                                    slot="start"
                                                    :value="option.sondaggi_domande_risposte_id"
                                                    class="ion-no-padding ion-no-margin ion-margin-end"
                                                ></ion-radio>
                                            </ion-item>
                                        </ion-radio-group>
                                    </div>

                                    <!-- Select (scelta multipla) -->
                                    <div v-if="domanda.sondaggi_domande_tipologia_value === 'Risposta singola'">
                                        <ion-select
                                            v-model="formFields[domanda.sondaggi_domande_id]"
                                            @ionChange="setSelectRisposta($event, domanda.sondaggi_domande_id)"
                                        >
                                            <ion-select-option
                                                v-for="(option, index) in domanda.risposte"
                                                :key="index"
                                                :value="option.sondaggi_domande_risposte_id"
                                            >
                                                {{ option.sondaggi_domande_risposte_risposta }}
                                            </ion-select-option>
                                        </ion-select>
                                    </div>

                                    <div v-if="domanda.sondaggi_domande_tipologia_value === 'Risposta multipla - checkbox'">
                                        <ion-select
                                            multiple
                                            v-model="formFields[domanda.sondaggi_domande_id]"
                                            @ionChange="setMultipleSelectRisposta($event, domanda.sondaggi_domande_id)"
                                        >
                                            <ion-select-option
                                                v-for="(option, index) in domanda.risposte"
                                                :key="index"
                                                :value="option.sondaggi_domande_risposte_id"
                                            >
                                                {{ option.sondaggi_domande_risposte_risposta }}
                                            </ion-select-option>
                                        </ion-select>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="empty_questions">
                                Nessuna domanda associata
                            </div>
                        </div>
                        <div class="save_checklist_container">
                            <button @click="saveChecklist()" :disabled="savingChecklist">Salva checklist</button>
                        </div>
                    </div>
                    <div v-else>
                        <div class="no_checklist">
                            Nessuna checklist da visualizzare
                        </div>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    //IonBackButton,
    IonTitle,
    IonContent,
    IonIcon,
    IonButton,
    IonSpinner,
    IonItem,
    IonInput,
    IonTextarea,
    IonLabel,
    IonRadio,
    IonRadioGroup,
    IonSelect,
    IonSelectOption,
    modalController,
    alertController,
} from "@ionic/vue";

import { defineComponent, toRefs, ref, computed } from "vue";

import { arrowBackOutline, trash, close } from "ionicons/icons";

import { dateFormat } from "@/services/utils";
import { openToast } from "@/services/toast";

import apiChecklist from "@/services/checklist";

export default defineComponent({
    name: "ChecklistPerizia",
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonButtons,
        //IonBackButton,
        IonTitle,
        IonContent,
        IonIcon,
        IonButton,
        IonSpinner,
        IonItem,
        IonInput,
        IonTextarea,
        IonLabel,
        IonRadio,
        IonRadioGroup,
        IonSelect,
        IonSelectOption,
    },
    setup(props, context) {
        const userID = JSON.parse(localStorage.getItem("userInfoAssitech")).users_id;

        const perizia = { ...props.data };

        const checklistSaved = ref(false);

        /********************************************************
         *
         * ! GESTIONE CHIUSURA MODALE CON E SENZA RITRONO DATI
         *
         ********************************************************/
        const closeModal = () => {
            modalController.dismiss();
        };

        /**
         * Close modal after form data submit
         */
        async function closeModalOnSubmit() {
            if (checklistSaved.value) {
                await modalController.dismiss(true);
            } else {
                await modalController.dismiss();
            }
        }

        /********************************************************
         *
         * ! ALERT CHIUSURA MODALE
         *
         ********************************************************/
        async function handleCloseModal() {
            const alert = await alertController.create({
                header: "Attenzione",
                message: "Se esci dalla checklist dovrai ricominciare la compilazione. Sei sicuro di voler uscire?",
                backdropDismiss: false,
                buttons: [
                    {
                        text: "Annulla",
                        handler: () => {
                            //Chiusura
                        },
                    },
                    {
                        text: "Conferma chiusura",
                        handler: () => {
                            closeModal();
                        },
                    },
                ],
            });
            return alert.present();
        }

        /********************************************************
         *
         * ! CARICAMENTO CHECKLIST
         *
         ********************************************************/
        const checklist = ref(null);
        const loadingChecklist = ref(false);
        async function loadChecklist() {
            const sondaggioId = 1;
            const compagniaId = perizia.pratiche_assicurative_compagnia_id;
            const tipoDanno = perizia.pratiche_assicurative_tipologia_danno;

            loadingChecklist.value = true;
            //Disabilito button per evitare creazione rapportino se la richiesta della checklist ci mette troppo
            //savingRapportino.value = true;

            try {
                const res = await apiChecklist.getCheklist(sondaggioId, compagniaId, tipoDanno);
                console.log(res);
                if (res.data.status === 1) {
                    checklist.value = res.data.data;
                } else {
                    openToast(`Checklist non riconosciuta. Contattare l'assistenza`, "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Si è verificato un errore durante la richiesta della checklist", "toast_danger");
            } finally {
                loadingChecklist.value = false;
                //savingRapportino.value = false;
            }
        }
        loadChecklist();

        /********************************************************
         *
         * ! GESTIONE RISPOSTE CHECKLIST
         *
         ********************************************************/
        const formFields = ref({});
        // Popolo importo riserva dalla perizia - TK #11696
        if (perizia.pratiche_assicurative_importo_riserva && perizia.pratiche_assicurative_importo_riserva > 0) {
            formFields.value["17"] = perizia.pratiche_assicurative_importo_riserva;
        }

        function setRadioRisposta(event, reference) {
            const value = event.target.value;
            formFields.value[reference] = value;
        }

        function setSelectRisposta(selectedValues, domandaId) {
            // Gestisci la selezione multipla qui, ad esempio, memorizzando i valori selezionati nel tuo data o eseguendo altre azioni necessarie.
            const values = event.detail.value;
            console.log("Valori selezionati:", selectedValues);
            console.log("ID della domanda:", domandaId);
            // Puoi memorizzare i valori selezionati nel tuo data se necessario.
            // this.formFields[domandaId] = selectedValues;
        }

        function setMultipleSelectRisposta(selectedValues, domandaId) {
            // Gestisci la selezione multipla qui, ad esempio, memorizzando i valori selezionati nel tuo data o eseguendo altre azioni necessarie.
            const values = event.detail.value;
            console.log("Valori selezionati:", selectedValues);
            console.log("ID della domanda:", domandaId);
            // Puoi memorizzare i valori selezionati nel tuo data se necessario.
            // this.formFields[domandaId] = selectedValues;
        }

        /********************************************************
         *
         * ! SALVATAGGIO CHECKLIST
         *
         ********************************************************/
        const savingChecklist = ref(false);
        async function saveChecklist() {
            console.log(formFields.value);

            savingChecklist.value = true;

            const response = {
                data: null,
                success: false,
            };

            if (checklist.value && Object.keys(formFields.value).length === 0) {
                savingChecklist.value = false;
                openToast("Prima di salvare la checklist occorre rispondere alle domande", "toast_danger");
                return response;
            }

            if (checklist.value) {
                // Prendo le referenze alle proprietà dell'oggetto reattivo e costruisco un oggetto nel formato corretto
                const refs = toRefs(formFields.value);
                const formattedObject = {};
                for (const key in refs) {
                    formattedObject[`risposta[${key}]`] = refs[key].value;
                }

                // Creo oggetto FormData con le risposte
                const formData = new FormData();
                for (const key in formattedObject) {
                    formData.append(key, formattedObject[key]);
                }
                for (const key in refs) {
                    // Se il valore è un array (selezione multipla), itera attraverso di esso
                    if (Array.isArray(refs[key].value)) {
                        for (const selectedValue of refs[key].value) {
                            formData.append(`risposta[${key}][]`, selectedValue);
                        }
                    } else {
                        // Se il valore non è un array, aggiungilo direttamente
                        formData.append(`risposta[${key}]`, refs[key].value);
                    }
                }

                formData.append("user_id", userID);
                formData.append("sondaggio_id", checklist.value[0].sondaggio_id);
                formData.append("sondaggio_da_app", 1);
                formData.append("pratica_id", perizia.pratiche_assicurative_id);

                try {
                    const res = await apiChecklist.saveCompilazione(formData);

                    if (res.data.status == 0) {
                        openToast(`${res.data.txt}`, "toast_danger");
                        response.success = false;
                        return response;
                    } else if (res.data.status == 1) {
                        response.data = res.data;
                        response.success = true;
                        checklistSaved.value = true;
                        //Svuoto dati sondaggio
                        Object.assign(formFields, {});
                        //Messaggio successo e chiusura modale
                        openToast(`${res.data.txt}`, "toast_success");
                        closeModalOnSubmit();
                    }
                } catch (error) {
                    console.error(error);
                    openToast("Si è verificato un errore durante il salvataggio della checklist", "toast_danger");
                    return response;
                } finally {
                    savingChecklist.value = false;
                }
            }
        }

        return {
            close,
            trash,
            arrowBackOutline,
            dateFormat,
            closeModal,
            handleCloseModal,
            perizia,
            // Checklist
            loadingChecklist,
            checklist,
            formFields,
            setRadioRisposta,
            setSelectRisposta,
            setMultipleSelectRisposta,
            // salvataggio
            savingChecklist,
            saveChecklist,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    padding-top: 24px;
}
ion-spinner {
    width: 40px;
    height: 40px;
    color: #086fa3;
}

/* New rules */
.page_content {
    width: 100%;
    position: relative; /* to be used for new intervento btn */
    padding: 16px;
    min-height: 100%;
    background: #f8fafc;
}
.no_checklist {
    width: 100%;
    text-align: center;
    padding: 24px 12px;
    background-color: rgb(226, 230, 254);
    color: rgb(68, 134, 239);
    font-size: 20px;
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}

.checklist .title_card {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 12px 6px;
    font-weight: 600;
    color: #1d1d1b;
}
.checklist .single_step_title {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 12px 6px;
    font-size: 18px;
    font-weight: 600;
    color: #1d1d1b;
}
.checklist {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}

.checklist {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.checklist .date {
    font-size: 14px;
    font-weight: 500;
    color: #1d1d1b;
}
.checklist .status {
    font-size: 14px;
}

ion-button {
    --color: #ffffff;
}

/**
* Struttura checklist
*/
.checklist_container {
    margin-top: 32px;
}
.single_step {
    width: 100%;
    margin-bottom: 20px;
}
.single_step .single_step_title {
    font-weight: bold;
    margin-bottom: 8px;
}
.single_step .single_field {
    margin-bottom: 16px;
}
.single_step .single_field .domanda_title {
    margin-bottom: 4px;
    color: #1d1d1b;
    font-weight: bold;
}

/**
* Input dinamici
*/
ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --padding-left: 0;
    --padding-right: 0;
    --inner-padding-end: 0;
    --ripple-color: transparent;
    --background-activated: transparent;
    --background-activated-opacity: 0;
    margin-bottom: 4px;
}

ion-datetime,
ion-select {
    max-width: 100%;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    --padding-start: 8px;
}

ion-input,
ion-textarea {
    --placeholder-color: #6b7280;
    --padding-start: 8px !important;
    --padding-end: 8px !important;
    margin-top: 0;
    border: 1px solid #d1d5db;
    border-radius: 4px;
}

/**
* Save checklist button
*/
.save_checklist_container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 32px 0;
}
.save_checklist_container button {
    width: 100%;
    text-align: center;
    padding: 12px;
    background-color: rgb(226, 230, 254);
    color: rgb(68, 134, 239);
    font-size: 20px;
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.25);
}
.save_checklist_container button:disabled {
    opacity: 0.3;
}

.empty_questions {
    color: #2273ad;
    text-align: center;
}
</style>
