<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title color="white">Verbale sopralluogo</ion-title>
                <ion-buttons slot="start">
                    <ion-button @click="handleCloseModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <!-- {{ checklist }} -->

                <div v-if="loadingChecklist" class="loading_spinner">
                    <h4>Caricamento verbale in corso</h4>
                    <ion-spinner name="crescent" color="primary"></ion-spinner>
                </div>
                <div v-else>
                    <div v-if="checklist && checklist.length != 0">
                        <div class="checklist">
                            <div v-for="(step, i) in checklist" :key="i" class="single_step">
                                <div class="single_step_title">{{ step.step }}</div>

                                <div v-if="step.domande && step.domande.length != 0" class="questions_container">
                                    <div v-for="(domanda, j) in step.domande" :key="j" class="single_field">
                                        <div class="domanda_title">{{ domanda.sondaggi_domande_domanda }}</div>

                                        <!-- Input type (risposta breve) -->
                                        <div v-if="domanda.sondaggi_domande_tipologia_value === 'Risposta breve'">
                                            <ion-input type="text" v-model="formFields[domanda.sondaggi_domande_id]"> </ion-input>
                                        </div>

                                        <!-- Textarea (risposta paragrafo) -->
                                        <div v-if="domanda.sondaggi_domande_tipologia_value === 'Paragrafo'">
                                            <ion-textarea v-model="formFields[domanda.sondaggi_domande_id]" rows="4" :auto-grow="true"></ion-textarea>
                                        </div>

                                        <!-- Radio (risposta singola) -->
                                        <div v-if="domanda.sondaggi_domande_tipologia_value === 'Risposta singola - radio'">
                                            <ion-radio-group
                                                @ionChange="setRadioRisposta($event, domanda.sondaggi_domande_id)"
                                                v-model="formFields[domanda.sondaggi_domande_id]"
                                            >
                                                <ion-item
                                                    v-for="(option, index) in domanda.risposte"
                                                    :key="index"
                                                    lines="none"
                                                    class="ion-no-padding ion-no-margin"
                                                    style="height: 40px;"
                                                >
                                                    <ion-label class="ion-no-padding ion-no-margin">{{
                                                        `${option.sondaggi_domande_risposte_risposta}`
                                                    }}</ion-label>
                                                    <ion-radio
                                                        slot="start"
                                                        :value="option.sondaggi_domande_risposte_id"
                                                        class="ion-no-padding ion-no-margin ion-margin-end"
                                                    ></ion-radio>
                                                </ion-item>
                                            </ion-radio-group>
                                        </div>

                                        <!-- Select (scelta singola) -->
                                        <div v-if="domanda.sondaggi_domande_tipologia_value === 'Risposta singola'">
                                            <ion-select
                                                v-model="formFields[domanda.sondaggi_domande_id]"
                                                @ionChange="setSelectRisposta($event, domanda.sondaggi_domande_id)"
                                            >
                                                <ion-select-option
                                                    v-for="(option, index) in domanda.risposte"
                                                    :key="index"
                                                    :value="option.sondaggi_domande_risposte_id"
                                                >
                                                    {{ option.sondaggi_domande_risposte_risposta }}
                                                </ion-select-option>
                                            </ion-select>
                                        </div>

                                        <!-- Select (scelta multipla) -->
                                        <div v-if="domanda.sondaggi_domande_tipologia_value === 'Risposta multipla - checkbox'">
                                            <ion-select
                                                multiple
                                                v-model="formFields[domanda.sondaggi_domande_id]"
                                                @ionChange="setMultipleSelectRisposta($event, domanda.sondaggi_domande_id)"
                                            >
                                                <ion-select-option
                                                    v-for="(option, index) in domanda.risposte"
                                                    :key="index"
                                                    :value="option.sondaggi_domande_risposte_id"
                                                >
                                                    {{ option.sondaggi_domande_risposte_risposta }}
                                                </ion-select-option>
                                            </ion-select>
                                        </div>

                                        <div v-if="domanda.sondaggi_domande_tipologia_value === 'Data'">
                                            <ion-datetime
                                                name="start_time"
                                                display-format="DD/MM/YYYY"
                                                picker-format="DD/MM/YYYY"
                                                min="2024"
                                                max="2099"
                                                slot="end"
                                                doneText="OK"
                                                cancelText="Annulla"
                                                v-model="formFields[domanda.sondaggi_domande_id]"
                                                placeholder="Data"
                                            ></ion-datetime>
                                        </div>
                                    </div>
                                </div>
                                <div v-else class="empty_questions">
                                    Nessuna domanda associata
                                </div>
                            </div>
                        </div>

                        <div class="email_input_container" :style="{ marginBottom: marginEmailContainer }">
                            <div class="label">Email a cui inviare copia del verbale</div>
                            <ion-input
                                v-model="email"
                                type="email"
                                inputmode="email"
                                clear-input
                                placeholder="Inserisci email"
                                @ionFocus="handleFocus"
                                @ionBlur="handleBlur"
                            >
                            </ion-input>
                        </div>

                        <div class="perizia_signatures_container">
                            <button type="button" @click="openCanvasFirme('Tecnico')" :disabled="tecnicoFirmato">Firma Perito</button>
                            <button type="button" @click="openCanvasFirme('Cliente')" :disabled="clienteFirmato">Firma Cliente</button>
                        </div>

                        <div class="save_checklist_container">
                            <button type="submit" @click="saveChecklist()" :disabled="savingChecklist">Salva verbale</button>
                        </div>

                        <CanvasFirme :isCanvasVisible="isCanvasFirmeVisible" :signer="currentSigner" @close="handleCloseCanvas" @save="handleSaveSignature" />
                    </div>
                    <div v-else>
                        <h4 class="ion-text-center">Nessun checklist associata</h4>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    //IonBackButton,
    IonTitle,
    IonContent,
    IonIcon,
    IonButton,
    IonItem,
    IonInput,
    IonTextarea,
    IonLabel,
    IonRadio,
    IonRadioGroup,
    IonSelect,
    IonSelectOption,
    IonDatetime,
    IonSpinner,
    modalController,
    alertController,
} from "@ionic/vue";

import { toRefs, ref, computed } from "vue";

import { arrowBackOutline, trash, close } from "ionicons/icons";

import moment from "moment";

import { dateFormat } from "@/services/utils";
import { openToast } from "@/services/toast";

import apiChecklist from "@/services/checklist";

import CanvasFirme from "@/components/perizie/CanvasFirme.vue";

export default {
    name: "VerbaleSopralluogo",
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonButtons,
        //IonBackButton,
        IonTitle,
        IonContent,
        IonIcon,
        IonButton,
        IonItem,
        IonInput,
        IonTextarea,
        IonLabel,
        IonRadio,
        IonRadioGroup,
        IonSelect,
        IonSelectOption,
        IonDatetime,
        IonSpinner,
        CanvasFirme,
    },
    setup(props, context) {
        const userID = JSON.parse(localStorage.getItem("userInfoAssitech")).users_id;

        const perizia = { ...props.data };

        const checklistSaved = ref(false);

        /********************************************************
         *
         * ! GESTIONE CHIUSURA MODALE
         *
         ********************************************************/
        const closeModal = () => {
            modalController.dismiss();
        };

        /**
         * Close modal after form data submit
         */
        async function closeModalOnSubmit() {
            if (checklistSaved.value) {
                await modalController.dismiss(true);
            } else {
                await modalController.dismiss();
            }
        }

        /********************************************************
         *
         * ! GESTIONE CANVAS PER FIRME
         *
         ********************************************************/
        const isCanvasFirmeVisible = ref(false);
        const currentSigner = ref("");
        function openCanvasFirme(signer) {
            //console.log(signer);
            currentSigner.value = signer;
            isCanvasFirmeVisible.value = true;
        }

        function handleCloseCanvas() {
            isCanvasFirmeVisible.value = false;
            currentSigner.value = "";
        }

        const technicianSignature = ref(null);
        const customerSignature = ref(null);

        const tecnicoFirmato = ref(false);
        const clienteFirmato = ref(false);

        function handleSaveSignature({ signer, content }) {
            if (content) {
                const base64Data = content.split(",")[1];

                if (signer === "Tecnico") {
                    // Salva firma tecnico
                    technicianSignature.value = base64Data;
                    tecnicoFirmato.value = true;
                } else {
                    // Salva firma cliente
                    customerSignature.value = base64Data;
                    clienteFirmato.value = true;
                }
            }
        }

        function clearSignatures() {
            technicianSignature.value = null;
            customerSignature.value = null;
        }

        /********************************************************
         *
         * ! ALERT CHIUSURA MODALE
         *
         ********************************************************/
        async function handleCloseModal() {
            const alert = await alertController.create({
                header: "Attenzione",
                message: "Se esci dal verbale dovrai ricominciare la compilazione. Sei sicuro di voler uscire?",
                backdropDismiss: false,
                buttons: [
                    {
                        text: "Annulla",
                        handler: () => {
                            //Chiusura
                        },
                    },
                    {
                        text: "Conferma chiusura",
                        handler: () => {
                            clearSignatures();
                            closeModal();
                        },
                    },
                ],
            });
            return alert.present();
        }

        /********************************************************
         *
         * ! CARICAMENTO SONDAGGIO
         *
         ********************************************************/
        const checklist = ref(null);
        const loadingChecklist = ref(false);
        async function loadVerbale() {
            const sondaggioId = 2;
            const compagniaId = perizia.pratiche_assicurative_compagnia_id;
            const tipoDanno = perizia.pratiche_assicurative_tipologia_danno;
            loadingChecklist.value = true;
            //Disabilito button per evitare creazione rapportino se la richiesta della checklist ci mette troppo
            //savingRapportino.value = true;

            try {
                const res = await apiChecklist.getCheklist(sondaggioId, compagniaId, tipoDanno);
                //console.log(res);
                if (res.data.status === 1) {
                    checklist.value = res.data.data;
                } else if (res.data.status == 0) {
                    openToast(`${res.data.txt}`, "toast_danger");
                } else {
                    openToast(`Checklist non riconosciuta. Contattare l'assistenza`, "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Si è verificato un errore durante la richiesta della checklist", "toast_danger");
            } finally {
                loadingChecklist.value = false;
                //savingRapportino.value = false;
            }
        }
        loadVerbale();

        /********************************************************
         *
         * ! GESTIONE RISPOSTE CHECKLIST
         *
         ********************************************************/
        const formFields = ref({});

        function setRadioRisposta(event, reference) {
            const value = event.target.value;
            formFields.value[reference] = value;
        }

        function setSelectRisposta(selectedValues, domandaId) {
            // Gestisci la selezione multipla qui, ad esempio, memorizzando i valori selezionati nel tuo data o eseguendo altre azioni necessarie.
            const values = event.detail.value;
            console.log("Valori selezionati:", selectedValues);
            console.log("ID della domanda:", domandaId);
            // Puoi memorizzare i valori selezionati nel tuo data se necessario.
            // this.formFields[domandaId] = selectedValues;
        }

        function setMultipleSelectRisposta(selectedValues, domandaId) {
            // Gestisci la selezione multipla qui, ad esempio, memorizzando i valori selezionati nel tuo data o eseguendo altre azioni necessarie.
            const values = event.detail.value;
            console.log("Valori selezionati:", selectedValues);
            console.log("ID della domanda:", domandaId);
            // Puoi memorizzare i valori selezionati nel tuo data se necessario.
            // this.formFields[domandaId] = selectedValues;
        }

        /**
         *
         * ! EMAIL DA NOTIFICARE
         *
         */
        const email = ref(null);
        const marginEmailContainer = ref("0");
        const handleFocus = () => {
            marginEmailContainer.value = "200px";
        };
        const handleBlur = () => {
            marginEmailContainer.value = "24px";
        };

        /********************************************************
         *
         * ! SALVATAGGIO CHECKLIST
         *
         ********************************************************/
        const savingChecklist = ref(false);
        async function saveChecklist() {
            //console.log(formFields.value);
            savingChecklist.value = true;

            const response = {
                data: null,
                success: false,
            };

            if (checklist.value && Object.keys(formFields.value).length === 0) {
                savingChecklist.value = false;
                openToast("Prima di salvare il verbale occorre rispondere alle domande", "toast_danger");
                return response;
            }

            if (checklist.value) {
                console.log(formFields.value);
                // Prendo le referenze alle proprietà dell'oggetto reattivo e costruisco un oggetto nel formato corretto
                const refs = toRefs(formFields.value);
                const formattedObject = {};
                for (const key in refs) {
                    formattedObject[`risposta[${key}]`] = refs[key].value;
                }

                // Creo oggetto FormData con le risposte
                const formData = new FormData();
                for (const key in refs) {
                    let value = refs[key].value;

                    // Check if the value is a date and format it using Moment.js
                    if (value && moment(value, moment.ISO_8601, true).isValid()) {
                        value = moment(value).format("DD/MM/YYYY");
                    }
                    // If the value is an array (multiple select), iterate through it
                    if (Array.isArray(value)) {
                        for (const selectedValue of value) {
                            formData.append(`risposta[${key}][]`, selectedValue);
                        }
                    } else {
                        // If the value is not an array, add it directly
                        formData.append(`risposta[${key}]`, value);
                    }
                }
                /* for (const key in refs) {
                    // Se il valore è un array (selezione multipla), itera attraverso di esso
                    if (Array.isArray(refs[key].value)) {
                        for (const selectedValue of refs[key].value) {
                            formData.append(`risposta[${key}][]`, selectedValue);
                        }
                    } else {
                        // Se il valore non è un array, aggiungilo direttamente
                        formData.append(`risposta[${key}]`, refs[key].value);
                    }
                } */

                /*  formData.forEach((value, key) => {
                    console.log(`${key}: ${value}`);
                });
                return; */

                formData.append("user_id", userID);
                formData.append("sondaggio_id", checklist.value[0].sondaggio_id);
                formData.append("sondaggio_da_app", 1);
                formData.append("pratica_id", perizia.pratiche_assicurative_id);
                formData.append("verbale", 1);
                // Email da notificare
                if (email.value) {
                    formData.append("email", email.value);
                }
                // Firma tecnico
                if (technicianSignature.value) {
                    formData.append("firma_tecnico", technicianSignature.value);
                }
                // Firma cliente
                if (customerSignature.value) {
                    formData.append("firma_cliente", customerSignature.value);
                }

                try {
                    const res = await apiChecklist.saveCompilazione(formData);

                    if (res.data.status == 0) {
                        openToast(`${res.data.txt}`, "toast_danger");
                        response.success = false;
                        return response;
                    } else if (res.data.status == 1) {
                        response.data = res.data;
                        response.success = true;
                        checklistSaved.value = true;
                        //Svuoto dati sondaggio
                        Object.assign(formFields, {});
                        // Svuoto campo email
                        email.value = null;
                        //Messaggio successo e chiusura modale
                        openToast(`${res.data.txt}`, "toast_success");
                        closeModalOnSubmit();
                    }
                } catch (error) {
                    console.error(error);
                    openToast("Si è verificato un errore durante il salvataggio del verbale", "toast_danger");
                    return response;
                } finally {
                    savingChecklist.value = false;
                }
            }
        }

        return {
            close,
            trash,
            arrowBackOutline,
            dateFormat,
            closeModal,
            handleCloseModal,
            perizia,
            // Checklist
            loadingChecklist,
            checklist,
            formFields,
            setRadioRisposta,
            setSelectRisposta,
            setMultipleSelectRisposta,
            // Firme
            isCanvasFirmeVisible,
            openCanvasFirme,
            handleCloseCanvas,
            handleSaveSignature,
            currentSigner,
            clienteFirmato,
            tecnicoFirmato,
            // Salvataggio checklist
            saveChecklist,
            // Email
            email,
            marginEmailContainer,
            handleFocus,
            handleBlur,
            savingChecklist,
        };
    },
};
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    padding-top: 24px;
}
ion-spinner {
    width: 40px;
    height: 40px;
    color: #086fa3;
}

/* New rules */
.page_content {
    width: 100%;
    position: relative; /* to be used for new intervento btn */
    padding: 16px;
    min-height: 100%;
    background: #f8fafc;
}

.checklist .title_card {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 12px 6px;
    font-weight: 600;
    color: #1d1d1b;
}
.checklist .single_step_title {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 12px 6px;
    font-size: 18px;
    font-weight: 600;
    color: #1d1d1b;
}

.checklist {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.checklist .date {
    font-size: 14px;
    font-weight: 500;
    color: #1d1d1b;
}
.checklist .status {
    font-size: 14px;
}

ion-button {
    --color: #ffffff;
}

/**
* Struttura checklist
*/
.checklist_container {
    margin-top: 32px;
}
.single_step {
    width: 100%;
    margin-bottom: 20px;
}
.single_step .single_step_title {
    font-weight: bold;
    margin-bottom: 8px;
}
.single_step .single_field {
    margin-bottom: 16px;
}
.single_step .single_field .domanda_title {
    margin-bottom: 4px;
    color: #1d1d1b;
    font-weight: bold;
}

/**
* Input dinamici
*/
ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --padding-left: 0;
    --padding-right: 0;
    --inner-padding-end: 0;
    --ripple-color: transparent;
    --background-activated: transparent;
    --background-activated-opacity: 0;
    margin-bottom: 4px;
}

ion-datetime,
ion-select {
    max-width: 100%;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    --padding-start: 8px;
}

ion-input,
ion-textarea {
    --placeholder-color: #6b7280;
    --padding-start: 8px !important;
    --padding-end: 8px !important;
    margin-top: 0;
    border: 1px solid #d1d5db;
    border-radius: 4px;
}

.empty_questions {
    color: #2273ad;
    text-align: center;
}
/**
* Save checklist button
*/
.save_checklist_container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 32px 0;
}
.save_checklist_container button {
    width: 100%;
    text-align: center;
    padding: 12px;
    /*  background-color: #e1e6fe;
    color: #4486ef; */
    background-color: #bbf7d0;
    color: #15803d;
    font-size: 18px;
    font-weight: 600;
    border-radius: 4px;
    /* box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.25); */
}
.save_checklist_container button:disabled {
    touch-action: none;
    opacity: 0.5;
}

.perizia_signatures_container {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 32px 0;
}
.perizia_signatures_container button {
    /*  width: 100%;
    text-align: center;
    padding: 12px;
    background-color: #e2e8f0;
    color: #64748b;
    font-size: 18px;
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.25); */
    width: 100%;
    text-align: center;
    padding: 12px;
    background-color: #e0e7ff;
    color: #6366f1;
    font-size: 18px;
    font-weight: 600;
    border-radius: 4px;
}

.perizia_signatures_container button:disabled {
    touch-action: none;
    opacity: 0.5;
}

.loading_container {
    display: flex;
    justify-content: center;
    color: #1d1d1b;
}

.email_input_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
    padding: 20px 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-top: 32px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}

.email_input_container .label {
    margin-bottom: 8px;
    color: #1d1d1b;
    font-weight: 500;
}
</style>
