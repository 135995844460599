<template>
    <div class="loading_spinner counter_photo_container">
        <ion-text color="dark">
            <h5 class="ion-text-center">Salvataggio in corso, il processo potrebbe richiedere del tempo in base alla connessione</h5>
            <h5 class="ion-text-center">{{ submitted }} di {{ allPhotosCounter }} processate correttamente</h5>
        </ion-text>
        <ion-spinner name="crescent" color="dark"></ion-spinner>
    </div>
</template>

<script>
import { IonText, IonSpinner } from "@ionic/vue";
export default {
    name: "ProcessingPhotos",
    props: {
        submitted: Number,
        allPhotosCounter: Number,
    },
    components: {
        IonText,
        IonSpinner,
    },
};
</script>

<style scoped>
.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.counter_photo_container {
    flex-direction: column;
    margin-bottom: 24px;
}
</style>
