<template>
    <div class="dati_principali">
        <div class="title">Perizia #{{ perizia.pratiche_assicurative_protocollo }}</div>
        <div class="main_info">
            <div class="row">
                <div class="title">Cliente</div>
                <div class="value">{{ riferimentoCliente() }}</div>
            </div>
            <div class="row">
                <div class="title">Compagnia</div>
                <div class="value">{{ perizia.compagnie_nome }}</div>
            </div>
            <div class="row">
                <div class="title">Tipologia danno</div>
                <div class="value">{{ perizia.tipologie_danni_nome ? perizia.tipologie_danni_nome : "-" }}</div>
            </div>
            <div class="row">
                <div class="title">Stato pratica</div>
                <div class="value">
                    <span class="badge" :class="statoPerizia(perizia.pratiche_assicurative_stato)">
                        {{ perizia.tipologie_stati_nome }}
                    </span>
                </div>
            </div>
        </div>
        <div class="more_info" @click="openPeriziaDetail">Vedi tutti i dettagli</div>
    </div>
</template>

<script>
export default {
    name: "DatiPrincipali",
    props: {
        perizia: Object,
        statoPerizia: Function,
    },
    emits: ["openPeriziaDetail"],
    setup(props, { emit }) {
        function riferimentoCliente() {
            const fields = [props.perizia.assicurati_nome, props.perizia.assicurati_cognome];
            return fields.filter(Boolean).join(" ");
        }

        const openPeriziaDetail = () => {
            emit("openPeriziaDetail");
        };

        return {
            riferimentoCliente,
            openPeriziaDetail,
        };
    },
};
</script>

<style scoped>
.dati_principali {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 20px 10px;
    margin-bottom: 24px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.dati_principali > .title {
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    color: #475569;
    margin-bottom: 16px;
}
.dati_principali .main_info {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 12px;
}
.dati_principali .row {
    display: flex;
    justify-content: space-between;
}
.dati_principali .row .title {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.dati_principali .row .value {
    font-size: 14px;
}
.dati_principali .more_info {
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    color: #086fa3;
    margin-top: 24px;
    text-align: center;
}
.badge {
    font-size: 10px;
    font-weight: 600;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}
</style>
