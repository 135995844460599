<template>
    <div v-if="canvasDrawings.length != 0" class="step_img_container">
        <ion-grid>
            <ion-row>
                <ion-col size="4" v-for="(draw, index) in canvasDrawings" :key="index">
                    <div class="singola_foto" @click="handleRemoveDraw(draw)">
                        <ion-thumbnail>
                            <img :src="draw" alt="" srcset="" class="img_photo" />
                        </ion-thumbnail>
                    </div>
                </ion-col>
            </ion-row>
        </ion-grid>
    </div>
</template>

<script>
import { defineComponent, toRefs } from "vue";
import { IonGrid, IonRow, IonCol, IonThumbnail } from "@ionic/vue";

export default defineComponent({
    name: "CanvasDrawingsGrid",
    props: {
        canvasDrawings: {
            type: Array,
            required: true,
        },
    },
    emits: ["removeDraw"],
    components: {
        IonGrid,
        IonRow,
        IonCol,
        IonThumbnail,
    },
    setup(props, { emit }) {
        const { canvasDrawings } = toRefs(props);

        const handleRemoveDraw = (draw) => {
            emit("removeDraw", draw);
        };

        return {
            handleRemoveDraw,
        };
    },
});
</script>

<style scoped>
.step_img_container {
    margin: 16px 0;
}

.singola_foto {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 8px;
}

.img_photo {
    width: 100%;
    height: auto;
    border-radius: 4px;
    cursor: pointer;
}
</style>
