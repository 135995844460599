
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonSpinner,
    IonIcon,
    IonButton,
    modalController,
    IonButtons,
    IonInput,
    menuController,
} from "@ionic/vue";
import { add, refresh, menu } from "ionicons/icons";

import { ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";

import { dateFormat } from "@/services/utils";
import { openToast } from "@/services/toast";
import apiPerizie from "@/services/perizie";

import PeriziaDetail from "@/components/perizie/PeriziaDetail.vue";
import NewPeriziaDetail from "@/components/perizie/NewPeriziaDetail.vue";

export default {
    name: "Perizie",
    components: {
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
        IonSpinner,
        IonIcon,
        IonButton,
        IonButtons,
        IonInput,
    },
    setup() {
        const loading = ref(false);
        const peritoID = JSON.parse(localStorage.getItem("userInfoAssitech")).periti[0].periti_id;

        const openMenu = () => {
            menuController.open("app-menu");
        };

        const perizie = ref([]);
        async function loadPerizie() {
            loading.value = true;
            try {
                const response = await apiPerizie.getPerizie(peritoID);

                if (response.status === 200 && response.data.status === 0) {
                    perizie.value = response.data.data;
                } else {
                    openToast("Errore durante la richiesta delle perizie", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta delle perizie", "toast_danger");
            } finally {
                loading.value = false;
            }
        }

        const searchQuery = ref("");
        const searchedPerizie = computed(() => {
            const term = searchQuery.value.replace(/ /g, "");

            return perizie.value.filter((perizia) => {
                if (perizia.assicurati_nome && perizia.assicurati_cognome) {
                    return (
                        perizia.assicurati_nome
                            .toLowerCase()
                            .replace(/ /g, "")
                            .indexOf(term.toLowerCase().replace(/ /g, "")) != -1 ||
                        perizia.assicurati_cognome
                            .toLowerCase()
                            .replace(/ /g, "")
                            .indexOf(term.toLowerCase().replace(/ /g, "")) != -1 ||
                        (perizia.assicurati_cognome + perizia.assicurati_nome)
                            .toLowerCase()
                            .replace(/ /g, "")
                            .indexOf(term.toLowerCase().replace(/ /g, "")) != -1 ||
                        (perizia.assicurati_nome + perizia.assicurati_cognome)
                            .toLowerCase()
                            .replace(/ /g, "")
                            .indexOf(term.toLowerCase().replace(/ /g, "")) != -1 ||
                        perizia.pratiche_assicurative_protocollo
                            .toLowerCase()
                            .replace(/ /g, "")
                            .indexOf(term.toLowerCase().replace(/ /g, "")) != -1
                    );
                } else if (perizia.assicurati_nome && !perizia.assicurati_cognome) {
                    return (
                        perizia.assicurati_nome
                            .toLowerCase()
                            .replace(/ /g, "")
                            .indexOf(term.toLowerCase().replace(/ /g, "")) != -1 ||
                        perizia.pratiche_assicurative_protocollo
                            .toLowerCase()
                            .replace(/ /g, "")
                            .indexOf(term.toLowerCase().replace(/ /g, "")) != -1
                    );
                } else if (!perizia.assicurati_nome && perizia.assicurati_cognome) {
                    return (
                        perizia.assicurati_cognome
                            .toLowerCase()
                            .replace(/ /g, "")
                            .indexOf(term.toLowerCase().replace(/ /g, "")) != -1 ||
                        perizia.pratiche_assicurative_protocollo
                            .toLowerCase()
                            .replace(/ /g, "")
                            .indexOf(term.toLowerCase().replace(/ /g, "")) != -1
                    );
                }
            });
        });

        /**
         * ! Set correct customer data
         */
        const riferimentoCliente = (perizia) => {
            if (perizia.assicurati_nome && perizia.assicurati_cognome) {
                return `${perizia.assicurati_nome} ${perizia.assicurati_cognome}`;
            } else if (perizia.assicurati_nome && !perizia.assicurati_cognome) {
                return `${perizia.assicurati_nome}`;
            }
            return `${perizia.assicurati_cognome}`;
        };

        /**
         * ! Set correct background for intervento status
         */
        const statoPerizia = computed(() => {
            return (statusId) => {
                let className = "";
                if (statusId == 1 || statusId == 9) {
                    //ticket aperti
                    className = "badge_red";
                } else if (statusId == 2 || statusId == 3) {
                    //ticket aperti
                    className = "badge_blue";
                } else if (statusId == 4 || statusId == 5) {
                    //ticket in lavorazione
                    className = "badge_orange";
                } else if (statusId == 6 || statusId == 7) {
                    //ticket in lavorazione
                    className = "badge_green";
                }
                return className;
            };
        });

        /**
         * ! Open perizia detail
         */
        async function periziaDetail(perizia) {
            const modal = await modalController.create({
                //component: PeriziaDetail,
                component: NewPeriziaDetail,
                componentProps: {
                    data: perizia,
                },
            });
            return modal.present();
        }

        onMounted(() => {
            //GET INTERVENTI DATA
            //loadInterventi();
            loadPerizie();
        });

        return {
            loading,
            add,
            refresh,
            //getRichieste,
            openMenu,
            menu,
            //Dati interventi
            statoPerizia,
            loadPerizie,
            dateFormat,
            riferimentoCliente,
            perizie,
            periziaDetail,
            searchQuery,
            searchedPerizie,
        };
    },
};
