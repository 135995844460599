import { ref } from "vue";
import { Camera, CameraSource, CameraResultType, CameraDirection } from "@capacitor/camera";

import exifr from "exifr";

export interface UserPhoto {
    filepath: string;
    webviewPath?: string;
}

/**
 * Pick gallery images
 * @returns Array with photos from gallery
 */
export function usePhotoGallery() {
    const photos = ref<UserPhoto[]>([]);
    const userPhoto = ref<UserPhoto[]>([]);

    const pickPhotos = async (selectedCategory, latitude, longitude, label) => {
        const { photos } = await Camera.pickImages({
            width: 1920,
            quality: 100,
            presentationStyle: "popover",
            limit: 4,
        });

        for (let index = 0; index < photos.length; index++) {
            //Use index for unique timestamp for each photo when select multiple photos
            const photo = photos[index];
            const fileName = new Date().getTime() + index + "." + photo.format;
            const savedFileImage = {
                filepath: fileName,
                webviewPath: photo.webPath,
                photoFromGallery: true,
                category: selectedCategory,
                latitude: latitude,
                longitude: longitude,
                label: label,
                jsonExif: null,
            };

            // Estraggo EXIF
            let exifData = null;
            exifData = await exifr.parse(`${photo.webPath}`);

            if (exifData) {
                savedFileImage.jsonExif = exifData;
            }

            userPhoto.value = [savedFileImage, ...userPhoto.value];
        }
    };

    /**
     * ! Take one single photo from camera
     */
    const takePhoto = async (selectedCategory, latitude, longitude, label) => {
        const cameraPhoto = await Camera.getPhoto({
            resultType: CameraResultType.Base64,
            //resultType: CameraResultType.Uri, //Nel caso di resize foto
            source: CameraSource.Camera,
            quality: 100,
            direction: CameraDirection.Rear,
            saveToGallery: true,
            width: 1920,
        });

        const fileName = new Date().getTime() + ".jpeg";
        const savedFileImage = {
            filepath: fileName,
            //webviewPath: cameraPhoto.webPath, //Nel caso di resize foto
            webviewPath: cameraPhoto.base64String, //usando Base64 come resultType
            photoFromGallery: false,
            //photoFromGallery: true, //Nel caso di resize foto
            category: selectedCategory,
            latitude: latitude,
            longitude: longitude,
            label: label,
            jsonExif: null,
        };

        // Estraggo EXIF
        let exifData = null;
        exifData = await exifr.parse(`${cameraPhoto.base64String}`);
        if (exifData) {
            savedFileImage.jsonExif = exifData;
        }

        userPhoto.value = [savedFileImage, ...userPhoto.value];
    };

    const deleteAllPhotos = () => {
        userPhoto.value = [];
    };

    /**
     * Remove photo from the Photos reference data array
     * @param photo images reference to remove
     */
    const deletePhoto = async (photo: UserPhoto) => {
        userPhoto.value = userPhoto.value.filter((p) => p.filepath !== photo.filepath);
    };

    /**
     * Convert from blob to base64
     * @param blob blob file to convert into base64
     * @returns
     */
    const convertBlobToBase64 = (blob: Blob) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onerror = reject;
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.readAsDataURL(blob);
        });

    async function convertPhoto(photo) {
        const response = await fetch(photo.webviewPath);
        const blob = await response.blob();
        const base64Data = (await convertBlobToBase64(blob)) as string;

        return base64Data;
    }

    return {
        userPhoto,
        takePhoto,
        deletePhoto,
        //convertPhoto,
        pickPhotos,
        deleteAllPhotos,
    };
}
